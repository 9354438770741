<template>
  <v-row class="d-flex align-center pa-0 ma-0 login-row">
    <v-col cols="12" md="6" class="pa-0 d-none d-sm-none d-md-flex login-left fill-height">
      <div class="wrapper d-flex flex-column" style="z-index:1">
        <v-row>
          <img
            src="@/assets/login/gns-software-logo-white.svg"
            alt="GNS Software Logo"
            width="250px"
          />
        </v-row>
        <v-row class="d-flex align-center">
          <v-col cols="auto">
            <h1>
              Bienvenidos
              <br />a Recibos Digitales
            </h1>
            <h3>Gestioná tus recibos de sueldo en un sólo lugar.</h3>
          </v-col>
        </v-row>
      </div>
      <div class="overlay"></div>
    </v-col>
    <v-col cols="12" md="6" class="pa-0 white login-right">
      <v-row
        class="wrapper d-flex flex-column justify-center px-8 px-sm-10"
        style="max-height:1000px;"
      >
        <div>
          <img
            src="@/assets/login/gns-software-logo.svg"
            alt="GNS Software Logo"
            class="d-flex d-md-none mb-6 logo"
          />
          <h1 class="inicio-sesion">
            Resetear contraseña
            <div class="left-color-bar"></div>
          </h1>
        </div>

        <v-form ref="resetForm" autocomplete="off" class="login-form mt-10 mb-4">
          <v-text-field
            label="Documento de identidad"
            v-model="reset.username"
            name="username"
            :rules="[rules.required]" 
            class="login-input"
            dense
            readonly
          />

          <v-text-field
            label="Empresa"
            v-model="reset.empresa"
            :rules="[rules.required]"
            name="CodigoEmp"
            class="login-input"
            dense
            readonly
          />

          <v-text-field
            v-model="reset.password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            label="Contraseña"
            counter
            @click:append="showPassword = !showPassword"
            class="login-input"
          ></v-text-field>

          <v-text-field
            v-model="reset.password_confirmation"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.confirmation]"
            :type="showPassword ? 'text' : 'password'"
            name="password_confirmation"
            label="Repetir Contraseña"
            counter 
            @click:append="showPassword = !showPassword"
            v-on:keyup.enter="processReset()"
            class="login-input"
          ></v-text-field>
          
          <v-progress-linear v-if="reset.password != ''"
            :value="progress"
            :color="color"
            height="7" 
            class="mt-2"
          ></v-progress-linear>

          <v-row>
            <v-col cols="12" sm="12" class="text-left">
              <a
                href="javascript:void(0)"
                v-on:click="navigate('login')"
              >
                Ir al Inicio
              </a>
            </v-col>
          </v-row>
        </v-form>

        <div class="d-flex flex-flow flex-wrap justify-space-between">
          <v-row>
            <v-col cols="12" xs="12" sm="12" class="text-right">
              <v-btn
                v-on:keyup.enter="processReset()"
                v-on:click="processReset()"
                class="btn-ingresar"
              >Reiniciar</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-row>
    </v-col>

    <v-snackbar
      v-model="$store.state.snackbar.show"
      :color="$store.state.snackbar.color"
      :timeout="$store.state.snackbar.timeout"
    ><div v-html="$store.state.snackbar.message"></div></v-snackbar>

    <v-dialog v-model="dialoglogin" max-width="450" :persistent="true">
      <v-card>
        <v-card-title class="headline">
          Atención
        </v-card-title>

        <v-card-text>
          Su contraseña ha sido cambiada correctamente. Ya puedes ingresar!
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary darken-1"
            text 
            @click="navigate('login')"
          >
            Inicias sesión
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { resetPassword } from "@/api/auth";

export default {
  name: "reset",
  components: {},

  data() {
    return {
      loading: false,
      showPassword: false,
      dialoglogin: false,

      reset: {
        username: "",
        empresa: "",
        password: "",
        password_confirmation: "",
        token: ""
      },

      rules: {
        required: value => !!value || 'Requerido.',
        confirmation: () => {
          return (this.reset.password == this.reset.password_confirmation) || 'Las contraseñas no coinciden'
        }
      }
    };
  },

  computed: {
    progress () {
      if(this.reset.password == null) return 0
      let strength = this.calculateStrength()
      return Math.min(100, strength * 100 / 5)
    },

    color () {
      return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
    },
  },

  created() {
    let query = this.$route.query.t
    let params = atob(query)
    let data = params.split('|')
    this.reset.username = data[0]
    this.reset.empresa = data[1]
    this.reset.token = data[2]
    this.$store.commit("setSnackBar", { show: false, message: null });
  },

  methods: {
    calculateStrength() {
      let strength = 0;
      let password = this.reset.password
      if (password.match(/[a-z]+/)) {
        strength += 1
      }
      if (password.match(/[A-Z]+/)) {
        strength += 1
      }
      if (password.match(/[0-9]+/)) {
        strength += 1
      }
      if (password.match(/[\W]+/)) {
        strength += 1
      }
      if (password.length > 6) {
        strength += 1
      }
      return strength
    },

    async processReset() {
      if(this.$refs.resetForm.validate(true)) {
        let data = null
        let msg = null
        try {
          this.saving = true

          data = await resetPassword(this.reset);
          this.dialoglogin = true

        } catch(e) {
          msg = e.response.data.message
        }

        if(!data) {
          this.$store.commit("setSnackBar", {
            message: msg,
            show: true,
            color:  "#cc0605",
            timeout: 5000,
          });
        }

        this.saving = false
      }
    },

    navigate(name) {
      this.$router.push({
          name: name,
      })
    }
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 550px;
  height: 100%;
  margin: 0 auto;
}

.login-left {
  background-image: url("../../assets/login/login-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 80%;
  background-position-y: 50%;
  color: white;
  position: relative;
}

.login-left h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 47px;
  line-height: 97.3%;
  letter-spacing: -0.0243em;
  color: #ffffff;
}

.login-left h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 107.3%;
  letter-spacing: -0.0143em;
  color: #ffffff;
  max-width: 350px;
}

.inicio-sesion {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  display: flex;
  align-items: center;
  letter-spacing: -0.0143em;
  color: #2d2d2d;
  position: relative;
}

.login-right {
  position: relative;
  background-color: white !important;
  height: 100%;
  max-height: 650px;
}

.login-right .logo {
  width: 250px;
}
.login-right .left-color-bar {
  position: fixed;
  left: 50%;
  width: 14px;
  height: 170px;
  background-color: #ff3301;
}
.overlay {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) -4.63%,
    rgba(196, 196, 196, 0) 103.53%
  );
  opacity: 0.7;
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

.btn-ingresar {
  background-color: #ff3301 !important;
  border-radius: 28px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff !important;
  box-shadow: none;
  width: 260px;
  height: 56px !important;
  text-transform: capitalize !important;
  margin-top: 2rem;
}

a {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #000000 !important;
}

.login-right .v-text-field .v-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #333333;
}

.login-right .v-input input {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  color: #757575;
  margin: 10px auto;
}

.login-row{
  background: white; 
  height: 100%;
}

@media (max-height: 700px) and (max-width: 991px) {
  .texto-bienvenida {
    display: none;
  }
}

@media (max-width: 960px) {
  .login-right .left-color-bar {
    left: 0;
  }
}

@media (max-width: 700px) {
  .login-right .left-color-bar {
    width: 6px;
    height: 80px;
  }
  .btn-ingresar {
    margin-top: 0 !important;
  }
}

@media (max-height: 850px) and (max-width: 991px) {
  .btn-ingresar {
    height: min-content !important;
    width: 100% !important;
    padding: 0.8rem !important;
  }
  .login-right .logo {
    max-width: 170px;
    max-height: 44px;
  }
  .inicio-sesion {
    font-size: 24px;
    line-height: 0;
  }
  .login-right .v-input input {
    margin: 0;
  }
  .btn-ingresar {
    margin-top: 0;
  }
  .login-right a {
    font-size: 14px;
  }
  .login-right {
    max-height: 500px;
    overflow: hidden !important;
    padding: 0;
  }
  .login-right .v-input {
    padding: 0 !important;
  }
  .login-righ > .row {
    padding: 4rem !important;
  }
  .login-row{
    display: flex !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0;
  }

  @media (max-height: 550px) and (max-width: 991px) {
    .login-form {
      margin-top: 2rem !important;
      margin-bottom: 0rem !important;
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
  }
  @media (max-height: 500px) and (max-width: 991px) {
    .login-form {
      margin-top: 1rem !important;
      margin-bottom: 0rem !important;
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
    .login-row{
      display: block !important;
    }
  }
}

.v-snack--bottom {
  margin: auto 0;
}
</style>
